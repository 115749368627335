import Vue from 'vue'
import Vuex from 'vuex'
import userInfo from './modules/userInfo'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersist({
	storage: window.sessionStorage
})

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		userInfo
	},
	plugins: [vuexLocal.plugin]
})
