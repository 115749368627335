import request from '../utils/request'
//登录
export const goLogin = params => {
	return request.post('/api/login', {...params})
}
//注册
export const logRegister = params =>{
    return request.post('/api/register', {...params})
}
//获取注册验证码
export const getRegisterCode = params =>{
    return request.post('/api/register_code', {...params})
}
//重置密码的邮箱
export const resetSend = params =>{
    return request.post('/api/reset_send', {...params})
}
//修改密码
export const resetPassword = params =>{
    return request.post('/api/reset_password', {...params})
}
//重置的密码
export const changePassword = params =>{
    return request.post('/api/change_password', {...params})
}

