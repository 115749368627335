export default {
	minNumber: 10, //充值最小金额
	TitleName: '飞渡', //首页标题
	hostURL: 'https://feidu.world/', //推荐
	baseURL: 'https://api.feidu.world/', //推荐
	Tutorial: 'https://rina389wjb.gitbook.io/feidu/',
	target:'_blank',
	Tglink: 'https://t.me/dufei888',
	Copyright: 'Copyright 2024 Feidu, Inc.All rights reserved.	',
	gotoRegulations: 'https://rina389wjb.gitbook.io/feidu/tiao-kuan', //阅读服务条例
	Notice: ` 
	Feidu推介计划：您每使用推介链接邀请一位用户注册，在 3 年内，当TA 充值时您就会获得TA 充值金额的 10% 作为佣金
	当您的推介佣金达到 100 元，请与客服联系（仅限当前拥有有效套餐的用户）
	`
}
