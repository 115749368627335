import { getUserInfo } from '../../api/user'
export default {
	namespaced: true,
	state: {
		userInfo: []
	},
	mutations: {
		setUserInfo(state, data) {
			state.userInfo = data
		}
	},
	actions: {
		async getUserInfo(context) {
			const data = await getUserInfo()
			context.commit('setUserInfo', data)
		}
	}
}
