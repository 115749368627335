import axios from 'axios'
import { message } from 'ant-design-vue'

axios.defaults.headers['Content-Type'] = 'text/plain;charset=utf-8'

// 创建axios实例
const service = axios.create({
	// axios中请求配置有baseURL选项，表示请求URL公共部分
	baseURL: 'https://api.feidu.world/',
	// 超时
	timeout: 10000
})
// request拦截器
service.interceptors.request.use(
	config => {
		if (sessionStorage.token) {
			const token = sessionStorage.token
			config.headers.Authorization = token
		}

		//
		// console.log('token', token)
		// 2.2 将 token 添加到请求头的 Authorization 属性中
		//
		return config
	},
	error => {
		console.log(error)
		Promise.reject(error)
	}
)

// 响应拦截器
service.interceptors.response.use(
	success => {
		// console.log('success.status', success )
		if (success.data.status == 504 || success.data.status == 404) {
			message.error('服务器发生错误')
		} else if (success.data.status == 403) {
			message.error('权限不足，请联系管理员')
		} else if (success.data.status == 401) {
			sessionStorage.token =[]; 
			message.error(' 尚未登录/登录过期')
			return (location.href = 'https://feidu.world/')
		}
		if (success.headers['authorization']) {
			sessionStorage.token = success.headers['authorization']
		}
		return success.data
	},
	error => {
		message.error('未捕捉服务器错误')
		return Promise.reject(error)
	}
)

export default service
