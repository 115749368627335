<template>
  <div>
    <footer class="footer">
      <div class="box">
        <router-link to="/dashboard">首页</router-link>
        <a :href="this.CONSTNAME.Tutorial" :target="this.CONSTNAME.target">教程文档</a>
        <a :href="this.CONSTNAME.Tglink" :target="this.CONSTNAME.target">Telegram</a>
      </div>
      <a-divider />
      <p class="font">{{ this.CONSTNAME.Copyright }}</p>
    </footer>
  </div>
</template>

<script>
export default {};

</script>

<style lang="scss" scoped>
.box {
  display: flex;
  width: 30%;
  justify-content: space-evenly;
  margin: 0 auto;

  a {
    color: #848587;
  }
}

.font {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  text-align: center;
  color: #848587;
}

.footer {
  width: 100%;
  padding: 20px;
}
</style>
