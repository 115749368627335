import request from '../utils/request'
//充值
export const paymentStatus = params => {
	return request.post('/api/payment', { ...params })
}
//充值记录
export const paymentHistory = currentPage => {
	return request.get(`/api/payment_history?page_index=${currentPage}&page_size=10`)
}
//推荐记录
export const invitationHistory = currentPage => {
	return request.get(`/api/invitation_history?page_index=${currentPage}&page_size=10`)
}
//重置订阅列表
export const resetSecret = params => {
	return request.post('/api/reset_secret', params)
}
//用户信息
export const getUserInfo = () => {
	return request.get('/api/user_info')
}
//退出登录
export const logout = () => {
	return request.post('/api/logout')
}
