import request from '../utils/request'
//套餐列表  不含参数
export const product = () => {
	return request.get('/api/product')
}
//购买套餐
export const productBuy = params => {
	return request.post('/api/product/buy', params)
}
//获取流量信息
export const getproductBuyTraffic = () => {
	return request.get(`/api/product/buy_traffic`)
}

//购买流量
export const productBuyTraffic = params => {
	return request.post('/api/product/buy_traffic', params)
}
//购买记录
export const purchaseHistory = currentPage => {
	return request.get(`/api/purchase_history?page_index=${currentPage}&page_size=10`)
}
//节点列表
export const servers = (type, currentPage) => {
	return request.get(`/api/servers?type=${type}&page_index=${currentPage}&page_size=10`)
}
//获取节点二维码
export const serversQrcode = params => {
	return request.post('/api/servers_qrcode', params)
}
//充值状态检查
export const paymentStatusyy = params => {
	console.log('params', params)
	return request.get(`/api/payment_status?${params}`)
}
