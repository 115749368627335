<template>
<div>
    <HeadCard />
    <div class="indexMain">
        <div class="indexMainLeft">
            <div class="loopBox">
                <div class="loopBoxTop">
                    <a-card>
                        <div slot="title" style="color: #1890ff; fontsize: 25px">
                            <a-icon type="windows" theme="filled" />
                            Windows
                        </div>
                    </a-card>
                </div>
                <div class="loopBoxBot">
                    <div class="loopBoxBotTex">
                        <p>Clash for Windows</p>
                    </div>
                    <div class="loopBoxBotBut">
                        <a-button>
                            <a href="https://rina389wjb.gitbook.io/feidu/untitled/windows-ke-hu-duan-jiao-cheng" target="_blank">使用教程</a>
                        </a-button>
                        <a-button>
                            <a href="https://ghproxy.com/https://github.com/clashdownload/Clash_for_Windows/releases/download/0.20.39/Clash.for.Windows.Setup.0.20.39.exe">
                                <a-icon type="vertical-align-bottom" />
                                下载软件
                            </a>
                        </a-button>
                        <a-button type="primary" v-clipboard:error="onError" v-clipboard:copy="subscribe_link_clash" v-clipboard:success="onCopy">复制订阅链接</a-button>
                    </div>
                </div>
            </div>
            <!-- l -->
            <div class="loopBox">
                <div class="loopBoxTop">
                    <a-card>
                        <div slot="title" style="color: #1890ff; fontsize: 25px">
                            <a-icon type="android" theme="filled" />
                            Android
                        </div>
                    </a-card>
                </div>
                <div class="loopBoxBot">
                    <div class="loopBoxBotTex">
                        <p>Clash for Android</p>
                    </div>
                    <div class="loopBoxBotBut">
                        <a-button>
                            <a href="https://rina389wjb.gitbook.io/feidu/untitled/android-ke-hu-duan-jiao-cheng" target="_blank">使用教程</a>
                        </a-button>
                        <a-button>
                            <a href="https://ghproxy.com/https://github.com/clashdownload/Clash_for_Android/releases/download/2.5.12/cfa-2.5.12-premium-universal-release.apk">
                                <a-icon type="vertical-align-bottom" />
                                下载软件
                            </a>
                        </a-button>
                        <a-button type="primary" v-clipboard:error="onError" v-clipboard:copy="subscribe_link_clash" v-clipboard:success="onCopy">复制订阅链接</a-button>
                    </div>
                </div>
            </div>
            <!-- 2 -->
            <div class="loopBox">
                <div class="loopBoxTop">
                    <a-card>
                        <div slot="title" style="color: #1890ff; fontsize: 25px">
                            <a-icon type="apple" theme="filled" />
                            iOS
                        </div>
                    </a-card>
                </div>
                <p class="loopBoxTopP" style="padding: 6px; box-sizing: border-box">
                    <a-icon type="exclamation-circle" theme="filled" />
                    &nbsp;&nbsp;请使用非大陆地区App Store下载软件
                </p>
                <div class="loopBoxBot">
                    <div class="loopBoxBotTex">
                        <p>Shadowrocket</p>
                    </div>
                    <div class="loopBoxBotBut">
                        <a-button>
                            <a href="https://rina389wjb.gitbook.io/feidu/untitled/ios-ke-hu-duan-jiao-cheng/shadowrocket-jiao-cheng" target="_blank">使用教程</a>
                        </a-button>
                        <a-button>
                            <a href="https://apps.apple.com/us/app/shadowrocket/id932747118">
                                <a-icon type="vertical-align-bottom" />
                                下载软件
                            </a>
                        </a-button>
                        <a-button type="primary" v-clipboard:error="onError" v-clipboard:copy="subscribe_link_clash" v-clipboard:success="onCopy">复制订阅链接</a-button>
                    </div>
                </div>
                <div class="loopBoxBot">
                    <div class="loopBoxBotTex">
                        <p>QuantumultX</p>
                    </div>
                    <div class="loopBoxBotBut">
                        <a-button>
                            <a href="https://rina389wjb.gitbook.io/feidu/untitled/ios-ke-hu-duan-jiao-cheng/quantumult-jiao-cheng" target="_blank">使用教程</a>
                        </a-button>
                        <a-button>
                            <a href="https://apps.apple.com/us/app/quantumult/id1252015438">
                                <a-icon type="vertical-align-bottom" />
                                下载软件
                            </a>
                        </a-button>
                        <a-button type="primary" v-clipboard:error="onError" v-clipboard:copy="subscribe_link_quantumultx" v-clipboard:success="onCopy">复制订阅链接</a-button>
                    </div>
                </div>

            </div>
            <!-- 3 -->
            <div class="loopBox">
                <div class="loopBoxTop">
                    <a-card>
                        <div slot="title" style="color: #1890ff; fontsize: 25px">
                            <a-icon type="apple" theme="filled" />
                            MacOS
                        </div>
                    </a-card>
                </div>
                <div class="loopBoxBot">
                    <div class="loopBoxBotTex">
                        <p>Clash X</p>
                    </div>
                    <div class="loopBoxBotBut">
                        <a-button>
                            <a href="https://rina389wjb.gitbook.io/feidu/untitled/macos-ke-hu-duan-jiao-cheng/clashx-jiao-cheng" target="_blank">使用教程</a>
                        </a-button>
                        <a-button>
                            <a href="https://dl.clashx.org/releases/latest/ClashX.dmg">
                                <a-icon type="vertical-align-bottom" />
                                下载软件
                            </a>
                        </a-button>
                        <a-button type="primary" v-clipboard:error="onError" v-clipboard:copy="subscribe_link_clash" v-clipboard:success="onCopy">复制订阅链接</a-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="indexMainRight noticeBox">
            <a-card title="公告" class="box1">
                <span>{{ this.CONSTNAME.Notice }}</span>
            </a-card>
            <a-card title="流量使用">
                <p>今日已用：{{ userInfo.today_used_traffic_gb }}G</p>
                <p>过去已用：{{ userInfo.used_traffic_gb }}G</p>
                <p>剩余流量：{{ userInfo.remain_traffic_gb }}G</p>
                <p>
                    流量到期日：{{ userInfo.expired_at | dateformat("YYYY-MM-DD") }}
                </p>
            </a-card>
        </div>
    </div>
    <Footer />
</div>
</template>

<script>
import HeadCard from "../../components/HeadCard";
import Footer from "../../components/Footer";
import moment from "moment";
import {
    createNamespacedHelpers
} from "vuex";

const {
    mapActions,
    mapState
} = createNamespacedHelpers("userInfo");

export default {
    data() {
        return {
            current: ["mail"],
            time: "",
            subscribe_link: "",
            subscribe_link_clash: "",
            subscribe_link_quantumultx: "",
            subscribe_link_shadowRocket: "",
        };
    },
    components: {
        HeadCard,
        Footer,
    },
    mounted() {
        this.timeData();
        console.log("prepare subscribe link ["+this.userInfo.subscribe_link+"] ...")
        this.subscribe_link = this.userInfo.subscribe_link;
        this.subscribe_link_clash = `${this.userInfo.subscribe_link}?c=clash`;
        this.subscribe_link_shadowRocket = `${this.userInfo.subscribe_link}?c=v2ray`;
        this.subscribe_link_quantumultx = `${this.userInfo.subscribe_link}?c=quanx`;
    },
    computed: {
        ...mapState(["userInfo"]),
    },
    methods: {
        onCopy() {
            this.$message.success("已成功复制到剪贴板");
        },
        // 复制失败
        onError() {
            this.$message.error("复制失败");
        },
        timeData() {
            const data = moment(this.userInfo.expired_at).format("YYYY-MM-DD");
            this.time = data;
        },
        ...mapActions(["getUserInfo"]),
    },
};
</script>

<style lang="scss" scoped>
.indexMain {
    margin: auto;
    margin-top: 20px;
    min-width: 1080px;
    width: 65%;
    height: auto;
    min-height: 50px;
    display: flex;
    justify-content: space-between;

    .indexMainLeft {
        width: 76.5%;
        min-height: 50px;
        padding: 6px;
        box-sizing: border-box;
        background-color: white;
        height: auto;

        box-sizing: border-box;

        .mainbox {
            width: 100%;
            height: 50px;
            border: 1px solid #e9e9e9;
        }

        .loopBox {
            width: 100%;
            min-height: 50px;
            box-sizing: border-box;
            padding: 10px 10px 25px 10px;

            // padding-bottom: 25px;
            .loopBoxTop {
                width: 100%;
                height: 65px;

                // border: 1px solid #e9e9e9;
                .ant-card-bordered {
                    border: none;
                }

                h1 {
                    padding-left: 10px;
                    color: #02a7f0;
                    line-height: 45px;
                }
            }

            .loopBoxBot {
                margin: auto;
                margin-top: 20px;
                width: 100%;
                height: 97px;
                box-sizing: border-box;
                padding: 5px;
                border: 1px solid #e9e9e9;
                // background-color: red;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;

                p {
                    font-weight: 700;
                    font-style: normal;
                    font-size: 20px;
                    margin-bottom: 0px;
                }

                div {
                    flex-grow: 1;

                    button {
                        width: 120px;
                        height: 40px;
                        margin-left: 15px;
                    }
                }

                .loopBoxBotBut {
                    text-align: right;
                    margin-right: 15px;
                }

                .loopBoxBotTex {
                    text-align: left;
                    margin-left: 15px;
                }
            }
        }
    }

    .indexMainRight {
        width: 22%;
        min-height: 50px;
        height: auto;
        box-sizing: border-box;

        // border: 1px solid red;
        .MainRightNotice {
            background-color: white;
            width: 290px;
            height: 170px;
            border: 1px solid #e9e9e9;
        }

        .MainRightUse {
            background-color: white;
            margin-top: 10px;
            width: 290px;
            height: 290px;
            border: 1px solid #e9e9e9;

            p {
                font-size: 18px;
            }

            .MainRightP {
                padding: 6px;
            }
        }

        .MainRightH {
            padding: 0px 8px;
            line-height: 65px;
            border-bottom: 1px solid #e9e9e9;
        }
    }

    .noticeBox {
        width: 22%;

        .box1 {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}
</style>
