<template >
	<div id="header">
		<a-card
			:headStyle="{ 'text-align': 'center', 'box-shadow': 'rgba(0, 21, 41, 0.08) 0px 1px 4px 0px', 'padding-right': '20%', 'min-width': '1200px' }"
			:tab-list="getnewdata.tabList"
			class="headerCard"
			:active-tab-key="key"
			@tabChange="key => onTabChange(key, 'key')"
		>
			<a slot="extra" href="#"></a>
			<span class="item" slot="customRender" slot-scope="item">
				<a-icon :type="item.icon" />
				{{ item.name }}
			</span>
			<template slot="tabBarExtraContent" class="ant-card-tabBarExtraContent">
				<a-popover>
					<div slot="content" class="userCenter">
						<div class="userBox">
							<router-link to="/account">
								<a-icon type="user" />
								个人中心
							</router-link>
						</div>
						<div class="line"></div>
						<div class="userBox">
							<a @click="logout">
								<a-icon type="poweroff" />
								退出登录
							</a>
						</div>
					</div>
					<div class="userIcon">
						<a-icon type="setting" theme="filled" />
						<span>
							{{ userInfo.email }}
						</span>
					</div>
				</a-popover>
			</template>
			<BreadcrumbCop class="breadcrumb" />
			<div class="card-container">
				<div v-if="$route.name === 'dashboard' || $route.name === 'nodeList'" class="card-container-box">
					<a-card-grid class="card-container-itemBox">
						<label>邮箱:</label>
						<span>{{ userInfo.email }}</span>
					</a-card-grid>
					<a-card-grid class="card-container-itemBox">
						<label>剩余流量:</label>
						<span>{{ userInfo.remain_traffic_gb }}GB</span>
					</a-card-grid>
					<a-card-grid class="card-container-itemBox">
						<label>账户余额:</label>
						<span>￥{{ userInfo.balance / 100 }}</span>
					</a-card-grid>
					<a-card-grid class="card-container-itemBox">
						<label>套餐到期时间:</label>
						<span>{{ userInfo.expired_at | dateformat('YYYY-MM-DD HH:MM:SS') }}</span>
					</a-card-grid>
				</div>

				<div v-if="$route.name === 'recharge'">
					<a-form labelAlign="right" :label-col="{ span: 6 }">
						<a-row>
							<a-descriptions>
								<a-descriptions-item label="当前套餐">{{ product_type[userInfo.product_type] }}</a-descriptions-item>
								<a-descriptions-item label="当前套餐剩余流量">{{ userInfo.remain_traffic_gb }} GB</a-descriptions-item>
							</a-descriptions>
						</a-row>
						<a-row>
							<a-descriptions>
								<a-descriptions-item label="账户余额">￥{{ userInfo.balance / 100 }}</a-descriptions-item>
								<a-descriptions-item label="当前套餐结束时间">{{ userInfo.expired_at | dateformat('YYYY-MM-DD HH:MM:SS') }}</a-descriptions-item>
							</a-descriptions>
						</a-row>
					</a-form>
				</div>
			</div>
		</a-card>
	</div>
</template>

<script>
/*eslint-disable*/
if (sessionStorage.token) {
	;(function (d, w, c) {
		w.ChatraID = 'wynqFmstzyGGNM3WQ	'
		var s = d.createElement('script')
		w[c] =
			w[c] ||
			function () {
				;(w[c].q = w[c].q || []).push(arguments)
			}
		s.async = true
		s.src = 'https://call.chatra.io/chatra.js'
		if (d.head) d.head.appendChild(s)
	})(document, window, 'Chatra')
}
import BreadcrumbCop from './Breadcrumb.vue'
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'
import api from '../api/api'

const { mapActions, mapState } = createNamespacedHelpers('userInfo')

export default {
	components: {
		BreadcrumbCop
	},
	mounted() {
		this.getUserInfo()
	},
	data() {
		return {
			time: '',
			tabList: [
				{
					key: 'dashboard',
					name: this.$t('headTitle.getHome'),
					icon: 'home',
					scopedSlots: { tab: 'customRender' }
				},
				{
					key: 'nodeList',
					name: this.$t('headTitle.nodeList'),
					icon: 'global',
					scopedSlots: { tab: 'customRender' }
				},
				{
					key: 'recharge',
					name: this.$t('headTitle.recharge'),
					icon: 'shop',
					scopedSlots: { tab: 'customRender' }
				},
				{
					key: 'recommend',
					name: this.$t('headTitle.recommend'),
					icon: 'check-circle',
					scopedSlots: { tab: 'customRender' }
				},
				{
					key: 'customerService',
					name: this.$t('headTitle.customerService'),
					icon: 'team',
					scopedSlots: { tab: 'customRender' }
				}
			],

			product_type: {
				try: '试用套餐',
				monthly: '月付套餐',
				quarterly: '季付套餐',
				yearly: '年付套餐'
			},

			key: this.$route.name
		}
	},
	computed: {
		...mapState(['userInfo']),
		getnewdata() {
			return {
				tabList: [
					{
						key: 'dashboard',
						name: this.$t('headTitle.home'),
						icon: 'home',
						scopedSlots: { tab: 'customRender' }
					},
					{
						key: 'nodeList',
						name: '节点列表',
						icon: 'global',
						scopedSlots: { tab: 'customRender' }
					},
					{
						key: 'recharge',
						name: '充值购买',
						icon: 'shop',
						scopedSlots: { tab: 'customRender' }
					},
					{
						key: 'recommend',
						name: '有奖推介',
						icon: 'check-circle',
						scopedSlots: { tab: 'customRender' }
					},
					{
						key: 'customerService',
						name: '客户服务',
						icon: 'team',
						scopedSlots: { tab: 'customRender' }
					}
				],

				key: this.$route.name
			}
		}
	},
	methods: {
		async logout() {
			const data = await api.logout()
			sessionStorage.token = ''
			this.$router.push('/user')
		},
		onTabChange(key, type) {
			this[type] = key
			this.$router.push(`/${key}`)
		},
		...mapActions(['getUserInfo'])
	}
}
</script>
<style scoped lang='scss'>
* {
	margin: 0;
	padding: 0;
}
.userCenter {
	margin: 0;
	padding: 0;
}
.userIcon {
	i {
		font-size: 16px;
	}
	span {
		margin-left: 5px;
		line-height: 16px;
	}
}

.line {
	width: 100%;
	margin: 10px 0 10px 0;
	border-bottom: 1px solid rgba($color: #000000, $alpha: 0.35);
}
.userBox {
	width: 100%;
	margin: 5px 10px 5px 10px;
	:hover {
		color: #3fa9ff;
	}
	a {
		color: rgba($color: #000000, $alpha: 0.65);
	}
}
#header {
	width: 100%;
	.item {
		font-size: 14px;
		line-height: 32px;
	}
	.breadcrumb {
		width: 65%;
		margin: 0 auto;
		min-width: 1080px;
	}
	.card-container {
		width: 66%;
		min-width: 1080px;
		margin: 0 auto;
		margin-top: 15px;
		.card-container-box {
			display: flex;
			justify-content: space-evenly;
		}
		.card-container-itemBox {
			display: flex;
			align-items: center;
			width: 25%;
			min-width: 265px;
			font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
			font-size: 14px;
			font-weight: 700;
			:nth-child(1) {
				font-size: 14px;
				padding: 10px;
			}
			:nth-last-child() {
				padding: 0 0 10px 10px;
			}
		}
		.orderDetails {
			margin: 20px;
			display: flex;
		}
	}
}
</style>