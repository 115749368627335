import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import LangUS from './assets/lang/en-US'
import LangZH from './assets/lang/zh-CN'
import VueClipboard from 'vue-clipboard2'
import moment from 'moment'

//按需引入Antd组件库
import {
	Button,
	Input,
	Icon,
	Checkbox,
	message,
	Layout,
	Menu,
	Skeleton,
	Avatar,
	Table,
	Card,
	Breadcrumb,
	Row,
	Col,
	Tabs,
	Radio,
	Badge,
	Form,
	Collapse,
	install,
	Modal
} from 'ant-design-vue'
//引入Antd组件库CSS文件
import 'ant-design-vue/dist/antd.css'
// import '~ant-design-vue/dist/antd.dark.css';
import constName from '../config'
Vue.prototype.CONSTNAME = constName
Vue.use(Button)
Vue.use(Collapse)
Vue.use(install)
Vue.use(Row)
Vue.use(Col)
Vue.use(VueI18n)
Vue.use(Card)
Vue.use(Form)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Checkbox)
Vue.use(Layout)
Vue.use(Menu)
Vue.use(Skeleton)
Vue.use(Avatar)
Vue.use(Breadcrumb)
Vue.use(Tabs)
Vue.use(Radio)
Vue.use(Table)
Vue.use(Badge)
Vue.use(Modal)
Vue.use(VueClipboard)
//message 是全局提示它的触发是方法调用的，所以直接绑定在Vue的原型上

//这样无论何时用Vue.message都可以弹出全局提示
Vue.prototype.$message = message
Vue.filter('dateformat', function(dataStr, pattern = 'YYYY-MM-DD') {
	if (dataStr) {
		return moment(dataStr).format(pattern)
	} else {
		return dataStr
	}
})

Vue.config.productionTip = false
// 引入router
import router from './router'
// 引入 vuex
import store from './store'
const i18n = new VueI18n({
	locale: 'zh-cn',
	messages: {
		'en-us': LangUS,
		'zh-cn': LangZH
	}
})

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-19R1SWXMLR" }
});

new Vue({
	router,
	i18n,
	store,
	render: h => h(App)
}).$mount('#app')
