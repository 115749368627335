<template>
	<!-- <a-config-provider :locale="locale"> -->
	<a-config-provider>
		<div id="app">
			<!-- <button @click="changeLocale('en-us')" v-show="localeval=='zh-cn'">English</button >
        <button @click="changeLocale('zh-cn')" v-show="localeval=='en-us'">中文</button > -->
			<router-view v-if='isRouterAlive'/>
		</div>
	</a-config-provider>
</template>
<script>
// import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
// import moment from 'moment'
// import 'moment/locale/zh-cn'
// moment.locale('zh-cn')
// const EN = 'en-us'
// const ZH = 'zh-cn'
export default {
	name: 'App',
	provide(){
		return { reload:this.reload,}
	},
	
	data() {
		return {
			// locale: zhCN,
			// localeval: 'zh-cn'
			
			isRouterAlive:true
		}
	},
	methods: {
		reload(){
			this.isRouterAlive = false
			this.$nextTick(function(){
				this.isRouterAlive = true
			})
		}
		// moment
		// changeLocale(localeval) {
		// 	this.localeval = localeval
		// 	if (localeval === EN) {
		// 		moment.locale(EN)
		// 		this.$i18n.locale = EN
		// 		this.locale = null
		// 	} else {
		// 		moment.locale(ZH)
		// 		this.$i18n.locale = ZH
		// 		this.locale = zhCN
		// 	}
		// }
	}
}
</script>
<style lang="scss">
</style>
