<template>
	<div>
		<a-breadcrumb>
			<a-breadcrumb-item>
				<a>飞渡</a>
			</a-breadcrumb-item>
			<a-breadcrumb-item v-for="(item, index) of $route.matched" :key="index">
				<router-link :to="item.path">{{ item.meta.title }}</router-link>
			</a-breadcrumb-item>
	
		</a-breadcrumb>
	</div>
</template>

<script>
export default {
	watch: {
		$route: 'init'
	},
	mounted() {
		console.log(this.$route)
	},
	methods: {
		init() {
			console.log(this.$route)
		}
	}
}
</script>

<style scoped></style>
