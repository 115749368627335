module.exports = {
	headTitle: {
		home: 'Home',
		getHome: 'dashboard',
		nodeList: 'nodeList',
		recharge: 'recharge',
		recommend: 'recommend',
		customerService: 'customerService'
	}
}
