import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/home'
import Login from '../pages/login'
Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		redirect: '/user'
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		meta: { title: '首页' },
		component: Home
	},
	{
		path: '/nodeList',
		name: 'nodeList',
		meta: { title: '节点列表' },
		component: () => import('../pages/nodeList')
	},
	{
		path: '/recharge',
		name: 'recharge',
		meta: { title: '充值购买' },
		component: () => import('../pages/recharge'),
		children: [
			{
				path: 'purchase',
				name: 'purchase',
				meta: { title: '购买' },
				component: () => import('../pages/purchase')
			}
		]
	},
	{
		path: '/recommend',
		name: 'recommend',
		meta: { title: '有奖推介' },
		component: () => import('../pages/recommend')
	},
	{
		path: '/customerService',
		name: 'customerService',
		meta: { title: '客户服务' },
		component: () => import('../pages/customerService')
	},
	{
		path: '/user',
		name: 'user',
		component: Login,
		redirect: '/user/login',
		children: [
			{
				path: 'login',
				name: 'login',
				meta: { title: '登录' },
				component: () => import('../pages/login/tologin.vue')
			},
			{
				path: 'register',
				name: 'register',
				meta: { title: '注册' },
				component: () => import('../pages/login/register.vue')
			},
			{
				path: 'resetSend',
				name: 'resetSend',
				meta: { title: '找回密码' },
				component: () => import('../pages/login/resetSend.vue')
			},
			{
				path: 'resetPassword',
				name: 'resetPassword',
				meta: { title: '注册' },
				component: () => import('../pages/login/resetPassword.vue')
			}
		]
	},
	{
		path: '/account',
		name: 'account',
		meta: { title: '账户设置' },
		component: () => import('../pages/account')
	}
]

const router = new VueRouter({
	routes
})
router.beforeEach((to, from, next) => {
	// to: Route: 即将要进入的目标 路由对象
	// from: Route: 当前导航正要离开的路由
	// next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
	const nextRoute = ['dashboard', 'nodeList', 'recharge', 'purchase', 'recommend', 'account']
	let isLogin = localStorage.getItem('token')
	// 未登录状态；当路由到nextRoute指定页时，跳转至login
	if (nextRoute.indexOf(to.name) >= 0 && !sessionStorage.token) {
		next({
			path: '/user'
			// 将跳转的路由path作为参数，登录成功后跳转到该路由
			// query: {redirect: to.fullPath}
		})
	} else {
		next()
	}
	// 已登录状态；当路由到login时，跳转至home
	if (to.name === 'user') {
		if (isLogin) {
			console.log('已登录')
			router.push({ path: '/dashboard' })
		}
	}
})
export default router
